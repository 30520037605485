import React from "react";

const SearchBar = ({ setSearchTerm, searchLabel, searchid }) => {
  return (
    <div className="form-group searchbar">
      <label htmlFor={searchid} style={{ width: "100%" }} className="form-group">
        {searchLabel}
        <input type="text" id={searchid} className="form-control" onChange={(e) => setSearchTerm(e.target.value)} autoComplete="off" />
      </label>
    </div>
  );
};

export default SearchBar;
