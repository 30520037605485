import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import DispatchContext from "../../config/DispatchContext";

import StateContext from "../../config/StateContext";

const login = <FontAwesomeIcon icon={faSignInAlt} />;
const logout = <FontAwesomeIcon icon={faSignOutAlt} />;

const CommonNavsSignedIn = () => {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const handleLogout = () => {
    appDispatch({ type: "logout" });
    appDispatch({ type: "flashMessage", value: "På gensyn " });
    localStorage.removeItem("user");
  };

  return (
    <nav>
      <ul className="sidebarnav">
        <li>{appState.loggedIn && <button onClick={handleLogout}>{logout} logout</button>}</li>
      </ul>
    </nav>
  );
};

const CommonNavsSignedOut = () => {
  let navigate = useNavigate();

  return (
    <ul className="sidebarnav">
      <li className="nav-item">
        {/* <button onClick={() => navigate("/login")}>
          {login} <span>Log in</span>
        </button>*/}
      </li>
    </ul>
  );
};

export default { CommonNavsSignedIn, CommonNavsSignedOut };
