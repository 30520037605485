import React from "react";

import { CSSTransition } from "react-transition-group";

const StationaryInfoElement = ({ validatedispatch, validateState }) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor="name">Navn på artikel</label>
        <input
          className="form-control"
          type="text"
          id="name"
          name="name"
          onChange={(e) => validatedispatch({ type: "nameImmediatly", value: e.target.value })}
          value={validateState.name.value}
          autoComplete="off"
        />
        <CSSTransition in={validateState.name.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <small className="alert alert-danger small liveValidateMessage">{validateState.name.message}</small>
        </CSSTransition>
      </div>

      <div className="form-group">
        <label htmlFor="description">Beskrivelse</label>
        <textarea
          className="form-control"
          type="text"
          id="description"
          name="description"
          onChange={(e) => validatedispatch({ type: "descriptionImmediatly", value: e.target.value })}
          value={validateState.description.value}
        />
        <CSSTransition in={validateState.description.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <small className="alert alert-danger small liveValidateMessage">{validateState.description.message}</small>
        </CSSTransition>
      </div>

      <div className="form-group">
        <label htmlFor="qty">Antal på lager</label>
        <input
          className="form-control"
          type="number"
          id="qty"
          name="qty"
          onChange={(e) => validatedispatch({ type: "qtyImmediatly", value: e.target.value })}
          value={validateState.qty.value}
          keyboardtype="numeric"
        />
        <CSSTransition in={validateState.qty.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <small className="alert alert-danger small liveValidateMessage">{validateState.qty.message}</small>
        </CSSTransition>
      </div>

      <div className="form-group ui-toggle">
        <div>Skal returneres</div>
        <input
          className="form-control ui-toggle"
          type="checkbox"
          id="returnable"
          name="returnable"
          onChange={(e) => {
            validatedispatch({ type: "returnableImmediatly", value: !validateState.returnable });
          }}
          value={validateState.returnable}
          checked={validateState.returnable ? "checked" : ""}
        />
        <label htmlFor="returnable">
          <div></div>
        </label>
      </div>

      {validateState.returnable > 0 && (
        <div className="form-group">
          <label htmlFor="serialnumber">Evt. Serial nummer</label>
          <input
            className="form-control"
            type="text"
            id="serialnumber"
            name="serialnumber"
            onChange={(e) => validatedispatch({ type: "serialnumberImmediatly", value: e.target.value })}
            value={validateState.serialnumber.value}
          />
          <CSSTransition in={validateState.serialnumber.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
            <small className="alert alert-danger small liveValidateMessage">{validateState.serialnumber.message}</small>
          </CSSTransition>
        </div>
      )}
    </>
  );
};

export default StationaryInfoElement;
