import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
//import "./App.scss";
//imports
import { useImmerReducer } from "use-immer";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faBars } from "@fortawesome/free-solid-svg-icons";

//config

import DispatchContext from "./config/DispatchContext";
import StateContext from "./config/StateContext";

//components
import FlashMessages from "./components/FlashMessages";
import LoadingDotsIcons from "./components/LoadingDotsIcons";

///Pages
import Login from "./pages/Login/Login";
import SingleUser from "./pages/Users/SingleUser";
import SingleStationary from "./pages/Stationary/SingleStationary";
import SignUp from "./pages/SignUp/SignUp";
import NewStationary from "./pages/Stationary/NewStationary";
import AddCategory from "./pages/Category/AddCategory";
import AllStationaries from "./pages/Stationary/AllStationaries";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header";

const DashBoard = lazy(() => import("./pages/DashBoard/DashBoard"));
const Home = lazy(() => import("./pages/Home/Home"));
const NotFound = lazy(() => import("./pages/NotFound.js"));

function App() {
  let navigate = useNavigate();

  const myLoggedInRoutes = [
    ["/dashboard", false, <DashBoard />, <NotFound />],
    [
      "artikel/:uuid",
      false,
      <DashBoard>
        <SingleStationary />
      </DashBoard>,
      <NotFound />,
    ],

    [
      "/profil/:uid",
      false,
      <DashBoard>
        <SingleUser />
      </DashBoard>,
      <NotFound />,
    ],
    [
      "/user/edit/:uid",
      false,
      <DashBoard>
        <SignUp />
      </DashBoard>,
      <NotFound />,
    ],
    [
      "/signup",
      false,
      <DashBoard>
        <SignUp />
      </DashBoard>,
      <NotFound />,
    ],
    [
      "/addcategory",
      false,
      <DashBoard>
        <AddCategory />
      </DashBoard>,
      <NotFound />,
    ],

    [
      "/artikel",
      false,
      <DashBoard>
        <AllStationaries />
      </DashBoard>,
      <NotFound />,
    ],
    [
      "/artikel/new/user/:uid",
      false,
      <DashBoard>
        <NewStationary />
      </DashBoard>,
      <NotFound />,
    ],
    [
      "/artikel/new",
      false,
      <DashBoard>
        <NewStationary />
      </DashBoard>,
      <NotFound />,
    ],
  ];

  const myPublicRoutes = [
    ["/login", <Login />],
    ["*", <NotFound />], //allways place * as last item in array
  ];

  //const currentUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";

  const initialState = {
    loggedIn: Boolean(localStorage.getItem("user")),
    flashMessageges: [],
    user: JSON.parse(localStorage.getItem("user")),
    uid: { value: "", hasErrors: false, message: "", checkCount: 0 },
    password: { value: "", hasErrors: false, message: "", checkCount: 0 },
    roles: [],
    submitCount: 0,
  };

  const ourReducer = (draft, action) => {
    switch (action.type) {
      case "addRoles":
        console.log(action.value);
        return;

      case "uidImmediatly":
        draft.uid.hasErrors = false;
        draft.uid.value = action.value;
        if (!draft.uid.value) {
          draft.uid.hasErrors = true;
          draft.uid.message = "Brugernavn eller emailadresse er påkrævet";
        }

        return;

      case "passwordImmediatly":
        draft.password.hasErrors = false;
        draft.password.value = action.value;
        if (!draft.password.value) {
          draft.password.hasErrors = true;
          draft.password.message = "Password er påkrævet";
        }
        return;

      case "submitForm":
        if (!draft.uid.hasErrors && !draft.password.hasErrors) {
          draft.submitCount++;
        }

        return;

      case "login":
        draft.loggedIn = true;

        navigate(`/dashboard`);
        return;
      case "user":
        draft.user = JSON.parse(localStorage.getItem("user"));
        return;

      case "logout":
        draft.loggedIn = false;
        draft.uid.value = "";
        draft.password.value = "";
        draft.submitCount = 0;
        navigate(`/login`);
        return;

      case "flashMessage":
        draft.flashMessageges.push(action.value);
        return;

      default:
        return;
    }
  };

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  return (
    <div>
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <Header />
          <FlashMessages messages={state.flashMessageges} />
          <Suspense fallback={<LoadingDotsIcons />}>
            <Routes>
              {["/home", "/"].map((path, i) => (
                <Route path={path} element={state.loggedIn ? <DashBoard /> : <Login />} key={i} />
              ))}
              {myLoggedInRoutes.map((path, i) =>
                path[1] === true ? (
                  <Route path={path[0]} exact element={state.loggedIn ? path[2] : path[3]} key={i} />
                ) : (
                  <Route path={path[0]} element={state.loggedIn ? path[2] : path[3]} key={i} />
                )
              )}
              {myPublicRoutes.map((path, i) => (
                <Route path={path[0]} element={path[1]} key={i} />
              ))}
            </Routes>
          </Suspense>
          <Footer />
        </DispatchContext.Provider>
      </StateContext.Provider>
    </div>
  );
}

export default App;
