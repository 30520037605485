import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import StateContext from "../../config/StateContext";

import { useParams } from "react-router";

import Page from "../../layouts/Page";
import userService from "../../services/user.service";

const SingleStationary = () => {
  let navigate = useNavigate();

  const appState = useContext(StateContext);

  const [single, setSingle] = useState([]);
  const [category, setCategory] = useState([]);
  const [serialnum, setSerialNum] = useState([]);
  const [image, setImage] = useState([]);
  const [stock, setStock] = useState();
  const [createdby, setCreatedBy] = useState([]);
  const [updatedby, setUpdatedBy] = useState([]);

  const { uuid } = useParams();

  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    async function fetchResults() {
      setIsloading(true);
      try {
        const response = await userService.get("stationary", uuid);

        setSingle(response.data["stationary"]);
        setCategory(response.data["categories"]);
        setSerialNum(response.data["serialnumber"][0]);
        setImage(response.data["images"]);
        setStock(response.data["stock"][0]);
        setCreatedBy(response.data["createdby"][0]);
        setUpdatedBy(response.data["updatedby"][0]);

        // console.log(image);

        setIsloading(false);
      } catch (e) {
        console.log(e);
      }
    }

    fetchResults();
  }, [uuid]);

  return isloading ? (
    <></>
  ) : (
    <Page title={single.name} PageTitle={single.name && single.is_active && single.name.toUpperCase()}>
      <div className="stationary">
        {stock && (
          <p>
            <span>Antal på lager: {stock.qty} stk. </span>
            <br />
          </p>
        )}
        <p>
          <span>Skal returneres: {single.returnable ? "ja" : "nej"} </span>
          <br />
        </p>
        <br />
        <div>{single.description}</div>
        <br />
        {serialnum && (
          <>
            <div>Serialnummer: {serialnum.serialnumber}</div>
            <br />
          </>
        )}
        <h4>Bruges i kategorierne:</h4>
        {category.lenght > 0 ? category.map((cat, i) => <div key={i}>{cat.name}</div>) : <div>er ikke i brug</div>}
        <br />
        <h4>Billeder:</h4>
        {image && image.map((img, i) => <div key={i}>{img.title}</div>)} <br />
        {createdby && (
          <details>
            <summary>Oprettet af {createdby.uid}</summary>
            Oprettet d. {createdby.created_at}
          </details>
        )}
        {updatedby && updatedby.updated_at !== createdby.created_at && (
          <details>
            <summary>Opdateret af {updatedby.uid}</summary>
            <table>
              <tr>
                <td>Opdateret d.</td>
                <td>{updatedby.updated_at}</td>
              </tr>
              <tr>
                <td>Navn:</td>
                <td>{updatedby.name}</td>
              </tr>
              <tr>
                <td>Tlf. nr.</td>
                <td>{updatedby.phonenumber}</td>
              </tr>
              <tr>
                <td>E-mail</td>
                <td>{updatedby.email}</td>
              </tr>
            </table>
          </details>
        )}
      </div>
    </Page>
  );
};

export default SingleStationary;
