import React from "react";
import ThemeToggler from "../ThemeToggler/ThemeToggler";
import "./footer.scss";
const Footer = () => {
  return (
    <footer className="container">
      <div className="themetoggler">
        <span>🌞</span> <ThemeToggler /> <span>🌙</span>
      </div>
    </footer>
  );
};

export default Footer;
