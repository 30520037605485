import react from "react";

import Page from "../../layouts/Page";

import SignUpForm from "./SignUpForm";

const SignUp = () => {
  return (
    <Page title="Opret dig som bruger" description="oprettelse af bruger " PageTitle="Tilføj ny ansat">
      <section>
        <span>
          <SignUpForm />
        </span>
      </section>
    </Page>
  );
};

export default SignUp;
