import React, { useEffect } from "react";

import ContentWrapper from "./ContentWrapper";

const Page = (props) => {
  useEffect(() => {
    document.title = `${props.title}`;
    window.scrollTo(0, 0);
    if (props.description) {
      document.querySelector('meta[name="description"]').setAttribute("content", props.description);
    }
  }, [props.title, props.description, props.PageTitle]);

  return (
    <>
      <h1 style={{ textAlign: "center" }}>{props.PageTitle}</h1>
      <ContentWrapper>{props.children}</ContentWrapper>
    </>
  );
};

export default Page;
