import React from "react";

import Accordion from "react-bootstrap/Accordion";

const AccordionWrapper = (props) => {
  //console.log(props.alwaysOpen);
  return props.alwaysOpen ? (
    <Accordion defaultActiveKey={props.defaultActiveKey} alwaysOpen>
      {props.children}
    </Accordion>
  ) : (
    <Accordion defaultActiveKey={props.defaultActiveKey}>{props.children}</Accordion>
  );
};

export default AccordionWrapper;
