const pusnAndPopToArr = (arr, uuid) => {
  if (!arr.includes(uuid)) {
    //checking weather array contain the uuid
    arr.push(uuid); //adding to array because value doesnt exists
  } else {
    arr.splice(arr.indexOf(uuid), 1); //deleting
  }
};

export default pusnAndPopToArr;
