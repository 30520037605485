import React from "react";

import { CSSTransition } from "react-transition-group";

import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";

const StationaryImageElement = ({
  validatedispatch,
  validateState,
  Placeholderimage,
  handleImage,
  srcImg,
  setImage,
  crop,
  setCrop,
  getCroppedImg,
}) => {
  console.log(Placeholderimage);

  return (
    <>
      <div className="form-group">
        <label htmlFor="file" style={{ cursor: "pointer" }} className="knap">
          Vælg billede
        </label>
        <input
          style={{ display: "none" }}
          className="form-control"
          type="file"
          id="file"
          name="file"
          accept="image/*"
          onChange={handleImage}
          //value={validateState.result.value}
        />
        <CSSTransition in={validateState.result.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <small className="alert alert-danger small liveValidateMessage">{validateState.result.message}</small>
        </CSSTransition>
      </div>

      <div>
        {validateState.overLay && (
          <div id="overlay">
            <section className="container">
              <ReactCrop style={{ maxWidth: "100%" }} src={srcImg} onImageLoaded={setImage} crop={crop} onChange={setCrop} />
              <div>
                <button className="cropButton col-sm-12 " onClick={getCroppedImg}>
                  Beskær billede
                </button>
              </div>
            </section>
          </div>
        )}
        {validateState.result.value ? (
          <div>
            <img
              src={validateState.result.value}
              alt="cropped preview"
              onClick={() => validatedispatch({ type: "overLay", value: !validateState.overLay })}
              style={{ cursor: "pointer", width: "100%" }}
            />
          </div>
        ) : (
          <Placeholderimage
            onClick={() => validateState.result.value && validatedispatch({ type: "overLay", value: !validateState.overLay })}
            style={{ width: "100%" }}
          />
        )}
      </div>
    </>
  );
};

export default StationaryImageElement;
