import Axios from "axios";

import API_URL from "./API_URL";
import authHeader from "./auth-header";

const getAll = (e) => {
  return Axios.get(`${API_URL}/${e}`, {
    headers: authHeader(),
  });
};

const get = (e, id) => {
  return Axios.get(`${API_URL}/${e}/${id}`, {
    headers: authHeader(),
  });
};

const create = (e, data) => {
  return Axios.post(`${API_URL}/${e}`, data, {
    headers: authHeader(),
  });
};

const login = async (uid, password) => {
  return await Axios.post(`${API_URL}/login`, { uid, password });
};

const update = (e, id, data) => {
  return Axios.put(`${API_URL}/${e}/${id}`, data, {
    headers: authHeader(),
  });
};

const remove = (e, id) => {
  return Axios.delete(`${API_URL}/${e}/${id}`, {
    headers: authHeader(),
  });
};

const removeAll = (e) => {
  return Axios.delete(`${API_URL}/${e}`, {
    headers: authHeader(),
  });
};

const findByTitle = (e, searchValue) => {
  return Axios.get(`${API_URL}/${e}/search/${searchValue}`, {
    headers: authHeader(),
  });
};

const checkRole = async (role) => {
  return await Axios.get(API_URL + `checkrole/${role}`, {
    headers: authHeader(),
  });
};

const userService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  login,
  checkRole,
};

export default userService;
