import React, { useEffect, useState, useContext } from "react";

import { CSSTransition } from "react-transition-group";

import LoadingDotsIcons from "../../../components/LoadingDotsIcons";

import userService from "../../../services/user.service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCity, faHome, faUserClock } from "@fortawesome/free-solid-svg-icons";

const PossibleStationaryLocations = ({ validatedispatch, validateState }) => {
  const [locations, setLocations] = useState([]);

  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    async function fetchResults() {
      setIsloading(true);
      try {
        const response = await userService.getAll("getlocation");

        setLocations(response.data["locations"]);

        console.log(response.data["locations"]);
        setIsloading(false);
      } catch (e) {
        console.log(e);
      }
    }

    fetchResults();
  }, [validateState.clearForm]);

  const locationNameSwitch = (e) => {
    switch (e) {
      case "home":
        return "Hjemme";

      case "office":
        return "På kontoret";

      case "lendout":
        return "Udlånes";

      default:
        return;
    }
  };

  const locationIconSwitch = (e) => {
    switch (e) {
      case "home":
        return <FontAwesomeIcon icon={faHome} />;

      case "office":
        return <FontAwesomeIcon icon={faCity} />;

      case "lendout":
        return <FontAwesomeIcon icon={faUserClock} />;

      default:
        return;
    }
  };

  return isloading ? (
    <LoadingDotsIcons />
  ) : (
    <div>
     
      <div className="stationary__location">
        {locations &&
          locations.map((locale, i) => (
            <label htmlFor={`stationaryLocation${i}`} className="form-group" key={i}>
              <div>
                {locationIconSwitch(locale)} {locationNameSwitch(locale)}
              </div>
              <input
                style={{ visibility: "hidden" }}
                className="form-control "
                type="radio"
                id={`stationaryLocation${i}`}
                name="stationaryLocation"
                checked={validateState.location.value === locale ? "checked" : ""}
                value={locale}
                onChange={(e) => validatedispatch({ type: "stationaryLocationImmediatly", value: e.target.value })}
              />
            </label>
          ))}

        <CSSTransition in={validateState.location.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <small className="alert alert-danger small liveValidateMessage">{validateState.location.message}</small>
        </CSSTransition>
      </div>
    </div>
  );
};

export default PossibleStationaryLocations;
