import React from "react";

import AccordionWrapper from "./AccordionWrapper";

const AccordionBody = (props) => {
  return (
    <AccordionWrapper defaultActiveKey={props.defaultActiveKey} alwaysOpen={props.alwaysOpen}>
      {props.children}
    </AccordionWrapper>
  );
};

export default AccordionBody;
