import React, { useEffect, useState } from "react";

import { CSSTransition } from "react-transition-group";
import LoadingDotsIcons from "../../components/LoadingDotsIcons";

import userService from "../../services/user.service";

const EditUser = ({ uid, handleSubmit, dispatch, validateState, ValidateError }) => {
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    async function fetchResults() {
      setIsloading(true);
      try {
        const response = await userService.get("userprofile", uid);

        if (response.data["user"]) {
          const user = response.data["user"];

          console.log(response.data["user"]);

          dispatch({ type: "emailImmediatly", value: user.email });
          dispatch({ type: "uidImmediatly", value: user.uid });
          dispatch({ type: "nameImmediatly", value: user.name });
          dispatch({ type: "phonenumberImmediatly", value: user.phonenumber });

          setIsloading(false);
        }
      } catch (e) {
        console.log(e);
      }
    }

    fetchResults();
  }, [uid]);

  return isloading ? (
    <></>
  ) : (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center", paddingTop: "10px" }}>Rediger {validateState.uid.value} </h4>

      <div className="form-group">
        <label htmlFor="name" className="text-muted mb-1">
          <small>Navn</small>
        </label>
        <input
          className="form-control"
          type="text"
          id="name"
          name="name"
          onChange={(e) => dispatch({ type: "nameImmediatly", value: e.target.value })}
          autoComplete="name"
          value={validateState.name.value}
        />
        <CSSTransition in={validateState.name.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <ValidateError>{validateState.name.message}</ValidateError>
        </CSSTransition>
      </div>

      <div className="form-group">
        <label htmlFor="phonenumber" className="text-muted mb-1">
          <small>Telefon nummer</small>
        </label>
        <input
          className="form-control"
          type="text"
          id="phonenumber"
          name="phonenumber"
          onChange={(e) => dispatch({ type: "phonenumberImmediatly", value: e.target.value })}
          autoComplete="phone-number"
          value={validateState.phonenumber.value}
        />
        <CSSTransition in={validateState.phonenumber.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <ValidateError>{validateState.phonenumber.message}</ValidateError>
        </CSSTransition>
      </div>
      <div className="form-group">
        <label htmlFor="email" className="text-muted mb-1">
          <small>E-mail adresse</small>
        </label>
        <input
          className="form-control"
          type="email"
          id="email"
          name="email"
          onChange={(e) => dispatch({ type: "emailImmediatly", value: e.target.value })}
          autoComplete="email"
          value={validateState.email.value}
        />
        <CSSTransition in={validateState.email.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <ValidateError>{validateState.email.message}</ValidateError>
        </CSSTransition>
      </div>

      <div className="form-group">
        <input type="submit" className="form-controle btn btn-info" value="Opdater bruger" />
      </div>
    </form>
  );
};

export default EditUser;
