import React from "react";
import HeaderNav from "./Navs/HeaderNav";

const Header = () => {
  return (
    <header className="container">

      <HeaderNav />
    </header>
  );
};

export default Header;
