import React, { useEffect, useContext, useState } from "react";

import { useImmerReducer } from "use-immer";

import StateContext from "../../config/StateContext";

import DispatchContext from "../../config/DispatchContext";

import { CSSTransition } from "react-transition-group";

import Page from "../../layouts/Page";
import ValidateError from "../../components/Validation/ValidateError";
import userService from "../../services/user.service";

const AddCategory = () => {
  const formData = new FormData();

  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const initialValidateState = {
    name: { value: "", hasErrors: false, message: "", isUnique: false, checkCount: 0 },
    description: { value: "", hasErrors: false, message: "", checkCount: 0 },
    submitCount: 0,
    clearForm: false,
  };

  const validateReducer = (draft, action) => {
    switch (action.type) {
      case "nameImmediatly":
        draft.name.hasErrors = false;
        draft.name.value = action.value;
        if (!draft.name.value) {
          draft.name.hasErrors = true;
          draft.name.message = "Navn er et påkrævet felt";
        }
        return;
      case "nameAfterDelay":
        if (!draft.name.hasErrors && !action.noRequest) {
          draft.name.checkCount++;
        }
        return;

      case "nameUniqueResults":
        draft.name.hasErrors = true;
        draft.name.message = "Kategori med navnet " + draft.name.value + " eksistere allerede";
        return;

      case "descriptionImmediatly":
        draft.description.hasErrors = false;
        draft.description.value = action.value;
        if (!draft.description.value) {
          draft.description.hasErrors = true;
          draft.description.message = "Beskrivelse er et påkrævet felt";
        }
        return;

      case "descriptionAfterDelay":
        if (!draft.description.hasErrors) {
          draft.description.checkCount++;
        }
        return;

      case "submitForm":
        if (!draft.name.hasErrors && !draft.name.isUnique && !draft.description.hasErrors) {
          draft.submitCount++;
        }

        return;

      case "clearForm":
        draft.name.value = "";
        draft.description.value = "";
        draft.submitCount = 0;
        draft.clearForm = true;
        return;

      default:
        return;
    }
  };

  const [validateState, validatedispatch] = useImmerReducer(validateReducer, initialValidateState);

  const handleSubmit = (e) => {
    e.preventDefault();

    validatedispatch({ type: "nameImmediatly", value: validateState.name.value });
    validatedispatch({ type: "nameAfterDelay", value: validateState.name.value, noRequest: true });
    validatedispatch({ type: "descriptionImmediatly", value: validateState.description.value });
    validatedispatch({ type: "descriptionAfterDelay", value: validateState.description.value });

    validatedispatch({ type: "submitForm" });
  };

  //run delayed validation serialnumber
  useEffect(() => {
    if (validateState.name.value) {
      const delay = setTimeout(validatedispatch({ type: "nameAfterDelay" }), 800);

      return () => clearTimeout(delay);
    }
  }, [validateState.name.value]);

  //check if serialnumber is unique
  useEffect(() => {
    if (validateState.name.checkCount) {
      const controller = new AbortController();

      const config = {
        headers: { Authorization: `Bearer ${appState.user.token}` },
      };

      async function fetchResults() {
        try {
          const response = await userService.get("categoryexsists", validateState.name.value);
          if (response.data) {
            validatedispatch({
              type: "nameUniqueResults",
              value: response.data,
            });
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchResults();

      return () => controller.abort();
    }
  }, [validateState.name.checkCount]);

  useEffect(() => {
    if (validateState.submitCount) {
      const controller = new AbortController();

      formData.append("name", validateState.name.value);
      formData.append("description", validateState.description.value);

      async function fetchResults() {
        try {
          const response = await userService.create("addnewcategory", formData);

          if (response.data) {
            appDispatch({ type: "flashMessage", value: "Der er oprettet en ny kategori ved navn " + validateState.name.value });

            validatedispatch({ type: "clearForm" });
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchResults();

      return () => controller.abort();
    }
  }, [validateState.submitCount]);

  return (
    <Page title="Tilføj ny kategori" PageTitle="Tilføj ny kategori">
      <fieldset>
        <legend></legend>
        <form onSubmit={handleSubmit}>
          <section>
            <div>
              <div className="form-group">
                <label htmlFor="name" className="text-muted mb-1">
                  <small>Navn på artikel</small>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  name="name"
                  onChange={(e) => validatedispatch({ type: "nameImmediatly", value: e.target.value })}
                  value={validateState.name.value}
                />
                <CSSTransition in={validateState.name.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
                  <ValidateError>{validateState.name.message}</ValidateError>
                </CSSTransition>
              </div>
              <div className="form-group">
                <label htmlFor="description" className="text-muted mb-1">
                  <small>Beskrivelse</small>
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  id="description"
                  name="description"
                  onChange={(e) => validatedispatch({ type: "descriptionImmediatly", value: e.target.value })}
                  value={validateState.description.value}
                />
                <CSSTransition in={validateState.description.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
                  <ValidateError>{validateState.description.message}</ValidateError>
                </CSSTransition>
              </div>
            </div>
          </section>
          <div className="form-group">
            <input type="submit" value="Tilføj ny kategori" />
          </div>
        </form>
      </fieldset>
    </Page>
  );
};

export default AddCategory;
