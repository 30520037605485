import React from "react";

import { CSSTransition } from "react-transition-group";

const SignUpUser = ({ handleSubmit, dispatch, validateState, ValidateError }) => {
  return (
    <form  onSubmit={handleSubmit}>
     
      <div className="form-group">
        <label htmlFor="uid" className="text-muted mb-1">
          <small>Brugernavn</small>
        </label>
        <input
          className="form-control"
          type="text"
          id="uid"
          name="uid"
          onChange={(e) => dispatch({ type: "uidImmediatly", value: e.target.value })}
          autoComplete="username"
          value={validateState.uid.value}
        />
        <CSSTransition in={validateState.uid.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <ValidateError>{validateState.uid.message}</ValidateError>
        </CSSTransition>
      </div>
      <div className="form-group">
        <label htmlFor="name" className="text-muted mb-1">
          <small>Navn</small>
        </label>
        <input
          className="form-control"
          type="text"
          id="name"
          name="name"
          onChange={(e) => dispatch({ type: "nameImmediatly", value: e.target.value })}
          autoComplete="name"
          value={validateState.name.value}
        />
        <CSSTransition in={validateState.name.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <ValidateError>{validateState.name.message}</ValidateError>
        </CSSTransition>
      </div>

      <div className="form-group">
        <label htmlFor="phonenumber" className="text-muted mb-1">
          <small>Telefon nummer</small>
        </label>
        <input
          className="form-control"
          type="text"
          id="phonenumber"
          name="phonenumber"
          onChange={(e) => dispatch({ type: "phonenumberImmediatly", value: e.target.value })}
          autoComplete="phone-number"
          value={validateState.phonenumber.value}
        />
        <CSSTransition in={validateState.phonenumber.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <ValidateError>{validateState.phonenumber.message}</ValidateError>
        </CSSTransition>
      </div>
      <div className="form-group">
        <label htmlFor="email" className="text-muted mb-1">
          <small>E-mail adresse</small>
        </label>
        <input
          className="form-control"
          type="email"
          id="email"
          name="email"
          onChange={(e) => dispatch({ type: "emailImmediatly", value: e.target.value })}
          autoComplete="email"
          value={validateState.email.value}
        />
        <CSSTransition in={validateState.email.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <ValidateError>{validateState.email.message}</ValidateError>
        </CSSTransition>
      </div>

      {validateState.usercanlogin ? (
        <>
          <div className="form-group">
            <label htmlFor="password" className="text-muted mb-1">
              <small>Password</small>
            </label>
            <input
              className="form-control"
              type="password"
              id="password"
              name="password"
              onChange={(e) => dispatch({ type: "passwordImmediatly", value: e.target.value })}
              autoComplete="new-password"
              value={validateState.password.value}
            />
            <CSSTransition in={validateState.password.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
              <ValidateError>{validateState.password.message}</ValidateError>
            </CSSTransition>
          </div>
          <div className="form-group">
            <label htmlFor="password_confirmation" className="text-muted mb-1">
              <small>Bekræft password</small>
            </label>
            <input
              className="form-control"
              type="password"
              id="password_confirmation"
              name="password_confirmation"
              onChange={(e) =>
                dispatch({
                  type: "password_confirmationImmediatly",
                  value: e.target.value,
                })
              }
              autoComplete="password-confirmation"
              value={validateState.password_confirmation.value}
            />
            <CSSTransition in={validateState.password_confirmation.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
              <ValidateError>{validateState.password_confirmation.message}</ValidateError>
            </CSSTransition>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="form-group">
        <label htmlFor="usercanlogin" className="text-muted mb-1">
          <small>Giv bruger ret til at logge in</small>
        </label>
        <div className="ui-toggle">
          <input
            className="form-control ui-toggle"
            type="checkbox"
            id="usercanlogin"
            name="usercanlogin"
            onChange={(e) => dispatch({ type: "usercanloginImmediatly" })}
          />
          <label htmlFor="usercanlogin">
            <div></div>
          </label>
        </div>
      </div>

      <div className="form-group">
        <input type="submit" className="form-controle btn btn-info" value="Registrer ny bruger" />
      </div>
    </form>
  );
};

export default SignUpUser;
