import React, { useEffect, useState, useContext } from "react";

import "./categories.scss";

import { CSSTransition } from "react-transition-group";

import LoadingDotsIcons from "../../components/LoadingDotsIcons";

import { useNavigate } from "react-router-dom";

import StateContext from "../../config/StateContext";
import DispatchContext from "../../config/DispatchContext";
import userService from "../../services/user.service";

const GetAll = ({ validatedispatch, validateState }) => {
  let navigate = useNavigate();

  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const [categories, setCategories] = useState([]);

  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    async function fetchResults() {
      setIsloading(true);
      try {
        const response = await userService.getAll("category");

        setCategories(response.data["category"]);
        setIsloading(false);
      } catch (e) {
        console.log(e);
      }
    }

    fetchResults();
  }, [validateState.clearForm]);

  return isloading ? (
    <LoadingDotsIcons />
  ) : (
    <>
      <div className="categories">
        {categories.map((category, i) => (
          <label htmlFor={category.uuid} className="categories__card" key={i} style={{ cursor: "pointer" }}>
            {category.name}
            <div className="ui-toggle">
              <input
                className="form-control ui-toggle"
                id={category.uuid}
                type="checkbox"
                name="category[]"
                value={category.uuid}
                checked={validateState.category.value.includes(category.uuid) ? "checked" : ""}
                onChange={(e) => validatedispatch({ type: "categoryImmediatly", value: e.target.value })}
              />
              <label htmlFor={category.uuid}>
                <div></div>
              </label>
            </div>
          </label>
        ))}

        <CSSTransition in={validateState.category.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
          <small className="alert alert-danger small liveValidateMessage">{validateState.category.message}</small>
        </CSSTransition>
      </div>
    </>
  );
};

export default GetAll;
