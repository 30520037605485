import React, { useEffect, useContext } from "react";

import { useParams } from "react-router";

import { useImmerReducer } from "use-immer";

import DispatchContext from "../../config/DispatchContext";

import StateContext from "../../config/StateContext";
import ValidateError from "../../components/Validation/ValidateError";
import userService from "../../services/user.service";
import SignUpUser from "./SignUpUser";
import EditUser from "./EditUser";

//import DispatchContext from "../DispatchContext";

const SignUpForm = () => {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const initialValidateState = {
    uid: { value: "", hasErrors: false, message: "", isUnique: false, checkCount: 0 },
    name: { value: "", hasErrors: false, message: "", checkCount: 0 },
    email: { value: "", oldValue : "", hasErrors: false, message: "", isUnique: false, checkCount: 0 },
    phonenumber: { value: "", oldValue : "", hasErrors: false, message: "", isUnique: false, checkCount: 0 },
    password: { value: "", hasErrors: false, message: "", checkCount: 0 },
    usercanlogin: false,
    password_confirmation: { value: "", hasErrors: false, message: "", checkCount: 0 },
    submitCount: 0,
    clearForm: false,
  };

  //console.log(appState);

  const validateReducer = (draft, action) => {
    switch (action.type) {
      case "nameImmediatly":
        draft.name.hasErrors = false;
        draft.name.value = action.value;
        if (!draft.name.value) {
          draft.name.hasErrors = true;
          draft.name.message = "Navn er et påkrævet felt";
        }

        return;
      case "nameAfterDelay":
        if (!draft.name.hasErrors) {
          draft.name.checkCount++;
        }
        return;

      case "uidImmediatly":
        draft.uid.hasErrors = false;
        draft.uid.value = action.value;
        if (!draft.uid.value) {
          draft.uid.hasErrors = true;
          draft.uid.message = "Brugernavnet er et påkrævet felt";
        }

        return;

      case "uidAfterDelay":
        if (!draft.uid.hasErrors && !action.noRequest) {
          draft.uid.checkCount++;
        }
        return;

      case "uidUniqueResults":
        draft.uid.hasErrors = true;
        draft.uid.message = "Brugernavnet " + draft.uid.value + " eksistere allerede";
        return;

      case "phonenumberImmediatly":
        draft.phonenumber.hasErrors = false;
        draft.phonenumber.value = action.value;
        if (!draft.phonenumber.value) {
          draft.phonenumber.hasErrors = true;
          draft.phonenumber.message = "Telefonnummer er et påkrævet felt";
        }
        return;
      case "phonenumberAfterDelay":
        if (!/^([+]45|[+]0045|0045|\(45\))?(\s*\d){8}$/.test(draft.phonenumber.value) && draft.phonenumber.value) {
          draft.phonenumber.hasErrors = true;
          draft.phonenumber.message = "Telefonnummeret er ikke formateret korrekt";
        }
        if (!draft.phonenumber.hasErrors && !action.noRequest) {
          draft.phonenumber.checkCount++;
        }

        return;
      case "phonenumberUniqueResults":
        draft.phonenumber.hasErrors = true;
        draft.phonenumber.message = "Telefonnummeret " + draft.phonenumber.value + " eksistere allerede";
        return;

      case "emailImmediatly":
        draft.email.hasErrors = false;
        draft.email.value = action.value;
        if (!draft.email.value) {
          draft.email.hasErrors = true;
          draft.email.message = "E-mail er et påkrævet felt";
        }
        return;
      case "emailAfterDelay":
        if (!/^\S+@\S+$/.test(draft.email.value)) {
          draft.email.hasErrors = true;
          draft.email.message = "Valid E-mail er påkrævet";
        }

        if (!draft.email.hasErrors && !action.noRequest) {
          draft.email.checkCount++;
        }
        return;
      case "emailUniqueResults":
        draft.email.hasErrors = true;
        draft.email.message = "E-mail adressen " + draft.email.value + " eksistere allerede";
        return;

      case "usercanloginImmediatly":
        draft.usercanlogin = draft.usercanlogin === false ? true : false;

        return;

      case "passwordImmediatly":
        draft.password.hasErrors = false;
        draft.password.value = action.value;
        if (!draft.password.value && draft.usercanlogin) {
          draft.password.hasErrors = true;
          draft.password.message = "Password er påkrævet";
        }

        return;

      case "passwordAfterDelay":
        if (!draft.password.hasErrors) {
          draft.password.checkCount++;
        }
        return;

      case "password_confirmationImmediatly":
        draft.password_confirmation.hasErrors = false;
        draft.password_confirmation.value = action.value;
        if (
          (draft.password.value && !draft.password_confirmation.value) ||
          (draft.usercanlogin && !draft.password_confirmation.value) ||
          (draft.usercanlogin && !draft.password.value)
        ) {
          draft.password_confirmation.hasErrors = true;
          draft.password_confirmation.message = "Bekræft password er påkrævet";
        }

        return;

      case "password_confirmationAfterDelay":
        if (!draft.password_confirmation.hasErrors) {
          draft.password_confirmation.checkCount++;
        }
        return;

      case "passwordNoMatch":
        draft.password_confirmation.hasErrors = true;
        draft.password_confirmation.message = "Bekræft password er ikke ens med password";
        return;

      case "submitForm":
        if (
          !draft.uid.hasErrors &&
          !draft.uid.isUnique &&
          !draft.name.hasErrors &&
          !draft.phonenumber.hasErrors &&
          !draft.phonenumber.isUnique &&
          !draft.email.hasErrors &&
          !draft.email.isUnique &&
          !draft.password.hasErrors &&
          !draft.password_confirmation.hasErrors
        ) {
          draft.submitCount++;
        }

        return;

      case "clearForm":
        draft.uid.value = "";
        draft.name.value = "";
        draft.phonenumber.value = "";
        draft.email.value = "";
        draft.password.value = "";
        draft.password_confirmation.value = "";
        draft.submitCount = 0;
        draft.usercanlogin = false;
        draft.clearForm = true;
        return;

      default:
        return;
    }
  };
  const [validateState, dispatch] = useImmerReducer(validateReducer, initialValidateState);

  const { uid } = useParams();

  //run delayed validation for email
  useEffect(() => {
    if (validateState.password_confirmation.value) {
      const delay = setTimeout(() => dispatch({ type: "password_confirmationAfterDelay" }), 800);

      return () => clearTimeout(delay);
    }
  }, [validateState.password_confirmation.value]);

  //check for password matches
  useEffect(() => {
    if (validateState.password_confirmation.checkCount) {
      if (validateState.password.value && validateState.password.value !== validateState.password_confirmation.value) {
        dispatch({ type: "passwordNoMatch" });
      }
    }
  }, [validateState.password_confirmation.checkCount]);

  //run delayed validation for email
  useEffect(() => {
    if (validateState.email.value) {
      const delay = setTimeout(() => dispatch({ type: "emailAfterDelay" }), 800);

      return () => clearTimeout(delay);
    }
  }, [validateState.email.value]);

  //run delayed validation for email
  useEffect(() => {
    if (validateState.password.value) {
      const delay = setTimeout(() => dispatch({ type: "passwordAfterDelay" }), 800);

      return () => clearTimeout(delay);
    }
  }, [validateState.password.value]);

  //check if email is unique
  useEffect(() => {
    if (validateState.email.checkCount) {
      async function fetchResults() {
        try {
          const response = await userService.get("emailexsist", validateState.email.value);

          if (response.data) {
            dispatch({ type: "emailUniqueResults", value: response.data });
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchResults();
    }
  }, [validateState.email.checkCount]);

  //run delayed validation for name
  useEffect(() => {
    if (validateState.name.value) {
      const delay = setTimeout(() => dispatch({ type: "nameAfterDelay" }), 800);

      return () => clearTimeout(delay);
    }
  }, [validateState.name.value]);

  //run delayed validation username
  useEffect(() => {
    if (validateState.uid.value) {
      const delay = setTimeout(() => dispatch({ type: "uidAfterDelay" }), 800);

      return () => clearTimeout(delay);
    }
  }, [validateState.uid.value]);

  //check if username is unique
  useEffect(() => {
    if (validateState.uid.checkCount) {
      async function fetchResults() {
        try {
          const response = await userService.get("uidexsist", validateState.uid.value);

          if (response.data) {
            dispatch({ type: "uidUniqueResults", value: response.data });
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchResults();
    }
  }, [validateState.uid.checkCount]);

  //run delayed validation phonenumber
  useEffect(() => {
    if (validateState.phonenumber.value) {
      const delay = setTimeout(() => dispatch({ type: "phonenumberAfterDelay" }), 800);

      return () => clearTimeout(delay);
    }
  }, [validateState.phonenumber.value]);

  //check if phonenumber is unique
  useEffect(() => {
    if (validateState.phonenumber.checkCount) {
      async function fetchResults() {
        try {
          const response = await userService.get("phoneexsist", validateState.phonenumber.value);

          if (response.data) {
            dispatch({
              type: "phonenumberUniqueResults",
              value: response.data,
            });
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchResults();
    }
  }, [validateState.phonenumber.checkCount]);

  //check if username is unique
  useEffect(() => {
    if (validateState.submitCount) {
      const submitData = {
        name: validateState.name.value,
        uid: validateState.uid.value,
        phonenumber: validateState.phonenumber.value,
        email: validateState.email.value,
        usercanlogin: validateState.usercanlogin,
        password: validateState.password.value,
        password_confirmation: validateState.password_confirmation.value,
      };

      async function fetchResults() {
        try {
          const response = await userService.create("register", submitData);

          appDispatch({ type: "flashMessage", value: `Der er nu oprettet en bruger ved navn ${response.data.user.name}` });

          dispatch({ type: "clearForm" });
        } catch (e) {
          console.log(e);
        }
      }

      fetchResults();
    }
  }, [validateState.submitCount]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: "uidImmediatly", value: validateState.uid.value });
    dispatch({ type: "uidAfterDelay", value: validateState.uid.value, noRequest: true });
    dispatch({ type: "nameImmediatly", value: validateState.name.value });
    dispatch({ type: "nameAfterDelay", value: validateState.name.value });

    dispatch({ type: "emailImmediatly", value: validateState.email.value });
    dispatch({ type: "emailAfterDelay", value: validateState.email.value, noRequest: true });

    dispatch({ type: "phonenumberImmediatly", value: validateState.phonenumber.value });
    dispatch({ type: "phonenumberAfterDelay", value: validateState.phonenumber.value, noRequest: true });

    dispatch({ type: "passwordImmediatly", value: validateState.password.value });
    dispatch({ type: "passwordAfterDelay", value: validateState.password.value });
    dispatch({ type: "password_confirmationImmediatly", value: validateState.password_confirmation.value });
    dispatch({ type: "password_confirmationAfterDelay", value: validateState.password_confirmation.value });
    dispatch({ type: "submitForm" });
  };

  return uid ? (
    <EditUser uid={uid} handleSubmit={handleSubmit} dispatch={dispatch} validateState={validateState} ValidateError={ValidateError} />
  ) : (
    <SignUpUser handleSubmit={handleSubmit} dispatch={dispatch} validateState={validateState} ValidateError={ValidateError} />
  );
};

export default SignUpForm;
