import React from "react";

import "./formstep.scss";

const StepFormStatusDot = (props) => {
  var rows = [];
  for (var i = 0; i < props.numSteps; i++) {
    rows.push(<span key={i} className={`step ${props.currStep === i ? "finish " : ""}`}></span>);
  }

  return <div className="stepcontainer">{rows}</div>;
};

export default StepFormStatusDot;
