import React, { useEffect, useState } from "react";

const ThemeToggler = () => {
  const storedTheme = localStorage.getItem("theme");

  const lightMode = "Skift til Light Mode";

  const darkMode = "Skift til Dark Mode";

  const [themelabel, setThemelabel] = useState(storedTheme === "dark" ? lightMode : darkMode);

  const prefersDark = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;

  const defaultDark = storedTheme === "dark" || (storedTheme === null && prefersDark);
  const setDark = () => {
    localStorage.setItem("theme", "dark");

    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  };
  if (defaultDark) {
    setDark();
  }

  const toggleTheme = (e) => {
    if (e.target.checked) {
      setDark();
      setThemelabel(lightMode);
    } else {
      setLight();
      setThemelabel(darkMode);
    }
  };

  return (
    <div className="ui-toggle">
      <input
        className="form-control ui-toggle"
        type="checkbox"
        id="toggleTheme"
        name="toggleTheme"
        defaultChecked={defaultDark}
        onChange={toggleTheme}
      />
      <label htmlFor="toggleTheme">
        <div></div>
      </label>
    </div>
  );
};

export default ThemeToggler;
