import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import StateContext from "../../config/StateContext";

import { useParams } from "react-router";

import Page from "../../layouts/Page";
import userService from "../../services/user.service";

const SingleUser = () => {
  let navigate = useNavigate();

  const [userprofile, setUserprofile] = useState([]);

  const { uid } = useParams();

  const [isloading, setIsloading] = useState(false);

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    async function fetchResults() {
      setIsloading(true);
      try {
        const response = await userService.get("userprofile", uid);

        if (!response.data["user"]) {
          navigate(`/${uid}`);
        } else {
          setUserprofile(response.data["user"]);

          setIsloading(false);

          setRoles(response.data["roles"]);
        }
      } catch (e) {
        console.log(e);
      }
    }

    fetchResults();
  }, [uid]);

  return isloading ? (
    <></>
  ) : (
    <Page title={`${userprofile.uid}`}>
      <div className="userprofile">
        <p>
          <strong>Brugernavn:</strong> {userprofile.uid}
        </p>
        <p>
          <strong>Navn:</strong> {userprofile.name}
        </p>

        <p>
          <strong>Telefonnummer</strong> {userprofile.phonenumber}
        </p>
        <p>
          <strong>Email:</strong> {userprofile.email}
        </p>
      </div>
    </Page>
  );
};

export default SingleUser;
