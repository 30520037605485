import React, { useState, useContext, useEffect } from "react";

import "./login.scss";

import userService from "../../services/user.service";

import DispatchContext from "../../config/DispatchContext";
import StateContext from "../../config/StateContext";
import Page from "../../layouts/Page";

import { ReactComponent as Logo } from "../../Images/1600px_COLOURBOX14636590.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUser, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

import ValidateError from "../../components/Validation/ValidateError";

import { CSSTransition } from "react-transition-group";

const Login = () => {
  const fauser = <FontAwesomeIcon icon={faUser} />;
  const passw = <FontAwesomeIcon icon={faUnlockAlt} />;

  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const uid = appState.uid.value;
  const password = appState.password.value;

  useEffect(() => {
    if (appState.submitCount) {
      async function fetchResults() {
        try {
          const response = await userService.login(uid, password);

          if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data));

            appDispatch({ type: "user" });

            appDispatch({ type: "login" });

            appDispatch({ type: "flashMessage", value: "Du er logget in" });
          }
        } catch (e) {
          appDispatch({ type: "flashMessage", value: "Vi kan ikke finde nogle brugere med disse kriterier" });
          console.log(e);
        }
      }
      fetchResults();
    }
  }, [appState.submitCount]);

  const handleSubmit = (e) => {
    e.preventDefault();

    appDispatch({ type: "uidImmediatly", value: appState.uid.value });

    appDispatch({ type: "passwordImmediatly", value: appState.password.value });

    appDispatch({ type: "submitForm" });
  };

  return (
    <Page title="login for den gamle" description="login for den gamle og de andre">
      <section className="login">
        <article>
          <Logo className="logo" />
        </article>
        <form onSubmit={handleSubmit}>
          <h1>Log in</h1>
          <div className="form-group">
            <label htmlFor="uid">
              <small>Brugernavn</small>
            </label>
            <div className="form-element">
              <input type="text" name="uid" onChange={(e) => appDispatch({ type: "uidImmediatly", value: e.target.value })} />

              {fauser}
            </div>
            <CSSTransition in={appState.uid.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
              <ValidateError>{appState.uid.message}</ValidateError>
            </CSSTransition>
          </div>

          <div className="form-group">
            <label htmlFor="password">
              <small>Brugernavn</small>
            </label>
            <div className="form-element">
              <input type="password" name="password" onChange={(e) => appDispatch({ type: "passwordImmediatly", value: e.target.value })} />

              {passw}
            </div>
            <CSSTransition in={appState.password.hasErrors} classNames="liveValidateMessage" timeout={330} unmountOnExit>
              <ValidateError>{appState.password.message}</ValidateError>
            </CSSTransition>
          </div>
          <div className="form-group">
            <input className="btn btn-info" type="submit" value="Login" />
          </div>
        </form>
      </section>
    </Page>
  );
};

export default Login;
