export default function authHeader(users) {
  const currentUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""

 

  if (currentUser && currentUser.token) {

    return {
      Authorization: `Bearer ${currentUser.token}`,
      "Access-Control-Allow-Origin": "*",
    };
  } else {
    return {};
  }
}
