import React, { useState, useEffect, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { useNavigate } from "react-router-dom";

import Page from "../../layouts/Page";
import GetAll from "../Category/GetAll";
import PossibleStationaryLocations from "./Components/PossibleStationaryLocations";

import "./stationary.scss";
import StateContext from "../../config/StateContext";
import DispatchContext from "../../config/DispatchContext";

import pusnAndPopToArr from "../../components/pusnAndPopArr";

import ConvertBlob from "../../components/ConvertBlob";

import { ReactComponent as Placeholderimage } from "../../Images/noimageplaceholder.svg";
import userService from "../../services/user.service";
import StationaryInfoElement from "./Components/StationaryInfoElement";
import StationaryImageElement from "./Components/StationaryImageElement";
import StepFormStatusDot from "../../components/Validation/StepFormStatusDot";

import { useParams } from "react-router";

const NewStationary = () => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  let navigate = useNavigate();

  const { uid } = useParams();

  const [catok, setCatok] = useState(false);

  const formData = new FormData();

  const initialValidateState = {
    name: { value: "", hasErrors: false, message: "", isUnique: false, checkCount: 0 },
    description: { value: "", hasErrors: false, message: "", checkCount: 0 },
    returnable: 0,
    serialnumber: { value: "", hasErrors: false, message: "", isUnique: false, checkCount: 0 },
    qty: { value: "", hasErrors: false, message: "", checkCount: 0 },
    category: { value: [], hasErrors: false, message: "", checkCount: 0 },
    location: { value: "", hasErrors: false, message: "", checkCount: 0 },
    submitCount: 0,
    clearForm: false,
    result: { value: "", hasErrors: false, message: "", checkCount: 0 },
    overLay: false,
    formPages: ["Artikel Info", "Billede Info", "Føj til kategori / kategorier"],
    userUuid: "",
  };

  const validateReducer = (draft, action) => {
    switch (action.type) {
      case "appendUser":
        draft.formPages = initialValidateState.formPages;
        draft.formPages.push("Hvor skal emnet bruges");
        draft.userUuid = action.uid;
        return;

      case "nameImmediatly":
        draft.name.hasErrors = false;
        draft.name.value = action.value;
        if (!draft.name.value) {
          draft.name.hasErrors = true;
          draft.name.message = "Navn er et påkrævet felt";
        }
        return;
      case "nameAfterDelay":
        if (!draft.name.hasErrors && !action.noRequest) {
          draft.name.checkCount++;
        }
        return;

      case "nameUniqueResults":
        draft.name.hasErrors = true;
        draft.name.message = "Artikel med navnet " + draft.name.value + " eksistere allerede";
        return;

      case "serialnumberImmediatly":
        draft.serialnumber.hasErrors = false;
        draft.serialnumber.value = action.value;

        return;
      case "serialnumberAfterDelay":
        if (!draft.serialnumber.hasErrors && !action.noRequest) {
          draft.serialnumber.checkCount++;
        }

        return;
      case "serialnumberUniqueResults":
        draft.serialnumber.hasErrors = true;
        draft.serialnumber.message = "Serial nummeret " + draft.serialnumber.value + " eksistere allerede";
        return;

      case "descriptionImmediatly":
        draft.description.hasErrors = false;
        draft.description.value = action.value;
        if (!draft.description.value) {
          draft.description.hasErrors = true;
          draft.description.message = "Artiklens beskrivelse er et påkrævet felt";
        }
        return;

      case "descriptionAfterDelay":
        if (!draft.description.hasErrors) {
          draft.description.checkCount++;
        }
        return;

      case "qtyImmediatly":
        draft.qty.hasErrors = false;
        draft.qty.value = action.value;
        if (!draft.qty.value) {
          draft.qty.hasErrors = true;
          draft.qty.message = "Antal på lager er et påkrævet felt";
        }
        return;

      case "qtyAfterDelay":
        if (!draft.qty.hasErrors) {
          draft.qty.checkCount++;
        }
        return;

      case "returnableImmediatly":
        draft.returnable = action.value;
        return;

      case "categoryImmediatly":
        draft.category.hasErrors = false;

        if (action.value !== undefined) {
          pusnAndPopToArr(draft.category.value, action.value);
        }

        if (draft.category.value.length === 0) {
          draft.category.hasErrors = true;
          draft.category.message = "Vælg mindst en kategori";
        }
        return;

      case "stationaryLocationImmediatly":
        draft.location.hasErrors = false;
        draft.location.value = action.value;
        if (!draft.location.value) {
          draft.location.hasErrors = true;
          draft.location.message = "Vælg udleveringstype";
        }

        return;

      case "imageImmediatly":
        draft.result.hasErrors = false;
        draft.result.value = action.value;
        if (!draft.result.value) {
          draft.result.hasErrors = true;
          draft.result.message = "Du mangler at angive et billede";
        }

        return;

      case "overLay":
        draft.overLay = action.value;
        return;

      case "submitForm":
        if (
          !draft.name.hasErrors &&
          !draft.name.isUnique &&
          !draft.serialnumber.hasErrors &&
          !draft.serialnumber.isUnique &&
          !draft.description.hasErrors &&
          !draft.qty.hasErrors &&
          !draft.result.hasErrors &&
          !draft.category.hasErrors &&
          !draft.location.hasErrors
        ) {
          draft.submitCount++;
        }

        return;

      case "clearForm":
        draft.name.value = "";
        draft.description.value = "";
        draft.qty.value = "";
        draft.returnable = false;
        draft.category.value = [];
        draft.serialnumber.value = "";
        draft.submitCount = 0;
        draft.result.value = "";
        draft.location.value = "";
        draft.clearForm = true;
        setFormPagination(0);
        return;

      default:
        return;
    }
  };

  const [formpagination, setFormPagination] = useState(0);

  const [validateState, validatedispatch] = useImmerReducer(validateReducer, initialValidateState);

  const ImageBlob = validateState.result.value;

  ConvertBlob({ formData, ImageBlob });

  const [srcImg, setSrcImg] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ aspect: 4 / 3 });

  const handleImage = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));

    validatedispatch({ type: "overLay", value: !validateState.overLay });
    //setOverlay(true);
  };

  const getCroppedImg = async (e) => {
    e.preventDefault();
    try {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

      const base64Image = canvas.toDataURL("image/jpeg", 1);

      //setResult(base64Image);

      validatedispatch({ type: "imageImmediatly", value: base64Image });
    } catch (e) {
      console.log("crop the image");
    }

    validatedispatch({ type: "overLay", value: !validateState.overLay });
  };

  useEffect(() => {
    if (uid) {
      async function fetchResults() {
        try {
          const response = await userService.get("userprofile", uid);

          if (response.data) {
            //console.log(response.data.user.uuid);
            validatedispatch({ type: "appendUser", value: response.data.user.uuid, uid: uid });
            //validatedispatch({ type: "appendUser", value: uid });
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchResults();
    }
  }, [uid]);

  console.log(validateState.userUuid);

  //run delayed validation serialnumber
  useEffect(() => {
    if (validateState.name.value) {
      const delay = setTimeout(validatedispatch({ type: "nameAfterDelay" }), 800);

      return () => clearTimeout(delay);
    }
  }, [validateState.name.value]);

  //check if serialnumber is unique
  useEffect(() => {
    if (validateState.name.checkCount) {
      async function fetchResults() {
        try {
          const response = await userService.get("nameexsists", validateState.name.value);

          if (response.data) {
            validatedispatch({
              type: "nameUniqueResults",
              value: response.data,
            });
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchResults();
    }
  }, [validateState.name.checkCount]);

  //run delayed validation serialnumber
  useEffect(() => {
    if (validateState.serialnumber.value) {
      const delay = setTimeout(validatedispatch({ type: "serialnumberAfterDelay" }), 800);

      return () => clearTimeout(delay);
    }
  }, [validateState.serialnumber.value]);

  //check if serialnumber is unique
  useEffect(() => {
    if (validateState.serialnumber.checkCount) {
      async function fetchResults() {
        try {
          const response = await userService.get("serialexsists", validateState.serialnumber.value);

          if (response.data) {
            validatedispatch({
              type: "serialnumberUniqueResults",
              value: response.data,
            });
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchResults();
    }
  }, [validateState.serialnumber.checkCount]);

  const handleSubmit = (e) => {
    e.preventDefault();

    validatedispatch({ type: "nameImmediatly", value: validateState.name.value });
    validatedispatch({ type: "nameAfterDelay", value: validateState.name.value, noRequest: true });
    validatedispatch({ type: "descriptionImmediatly", value: validateState.description.value });
    validatedispatch({ type: "descriptionAfterDelay", value: validateState.description.value });
    validatedispatch({ type: "qtyImmediatly", value: validateState.qty.value });
    validatedispatch({ type: "serialnumberImmediatly", value: validateState.serialnumber.value });
    validatedispatch({ type: "imageImmediatly", value: validateState.result.value });
    validatedispatch({ type: "categoryImmediatly" });
    validatedispatch({ type: "stationaryLocationImmediatly", value: validateState.location.value });

    validatedispatch({ type: "submitForm" });
  };

  useEffect(() => {
    if (validateState.submitCount) {
      formData.append("file", validateState.result.value);
      formData.append("name", validateState.name.value);
      formData.append("description", validateState.description.value);
      formData.append("qty", validateState.qty.value);
      formData.append("returnable", validateState.returnable ? 1 : 0);
      formData.append("category", validateState.category.value);
      formData.append("location", validateState.location.value);
      uid && formData.append("uuid", validateState.userUuid);
      formData.append("serialnumber", validateState.serialnumber.value ? validateState.serialnumber.value : null);

      async function fetchResults() {
        try {
          const response = await userService.create("addnewstationary", formData);

          if (response.data) {
            appDispatch({ type: "flashMessage", value: "Der er oprettet en ny artikel ved navn " + validateState.name.value });

            validatedispatch({ type: "clearForm" });
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchResults();
    }
  }, [validateState.submitCount]);

  useEffect(() => {
    // used to prevent the creation of articles in case there is no categories for them
    async function fetchResults() {
      try {
        const response = await userService.getAll("category");

        if (response.data["category"].length === 0) {
          appDispatch({ type: "flashMessage", value: "Der skal være mindst 1 kategori for at kunne lave en artikel" });
          navigate("/addcategory");
        } else {
          setCatok(true);
        }
      } catch (e) {
        console.log(e);
      }
    }

    fetchResults();
  }, []);

  const formContentSwitch = () => {
    switch (formpagination) {
      case 0:
        return <StationaryInfoElement validatedispatch={validatedispatch} validateState={validateState} />;

      case 1:
        return (
          <StationaryImageElement
            validatedispatch={validatedispatch}
            validateState={validateState}
            Placeholderimage={Placeholderimage}
            handleImage={handleImage}
            srcImg={srcImg}
            setImage={setImage}
            crop={crop}
            setCrop={setCrop}
            getCroppedImg={getCroppedImg}
          />
        );

      case 2:
        return <GetAll validatedispatch={validatedispatch} validateState={validateState} />;

      case 3:
        return uid && <PossibleStationaryLocations validatedispatch={validatedispatch} validateState={validateState} />;

      default:
        return;
    }
  };

  return (
    catok && (
      <Page title="Tilføj ny kontorartikel" PageTitle="Tilføj ny kontorartikel">
        <form onSubmit={handleSubmit}>
          {uid && <h2 style={{ textAlign: "center" }}>Artikel til {uid}</h2>}
          <StepFormStatusDot numSteps={validateState.formPages.length} currStep={formpagination} />

          <h4>{validateState.formPages[formpagination]}</h4>

          {formContentSwitch()}

          <div className="form-group form-group__button">
            {formpagination > 0 && (
              <div
                style={{ cursor: "pointer" }}
                className="form-controle knap previous"
                onClick={() => {
                  setFormPagination((currPage) => currPage - 1);
                }}
              >
                &#xab; Forrige
              </div>
            )}
            {formpagination + 1 < validateState.formPages.length ? (
              <div
                style={{ cursor: "pointer" }}
                className="form-controle knap next"
                onClick={() => {
                  setFormPagination((currPage) => currPage + 1);
                }}
              >
                Næste &#xbb;
              </div>
            ) : (
              <input type="submit" className="form-controle  submit" value={`Gem artikel ${uid ? `til ` + uid : ""}`} />
            )}
          </div>
        </form>
      </Page>
    )
  );
};

export default NewStationary;
