import React from "react";

const ConvertBlob = ({ formData, ImageBlob }) => {
  //console.log("blob", ImageBlob);

  if (!ImageBlob) {
    return;
  }

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  var ImageURL = ImageBlob;
  // Split the base64 string in data and contentType
  var block = ImageURL.split(";");
  // Get the content type of the image
  var contentType = block[0].split(":")[1]; // In this case "image/gif"
  // get the real base64 content of the file
  var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

  // Convert it to a blob to upload
  var blob = b64toBlob(realData, contentType);

  // Create a FormData and append the file with "image" as parameter name

  formData.append("file", blob);

  return <div></div>;
};

export default ConvertBlob;
