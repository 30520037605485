import React, { useEffect, useState, useContext } from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import LoadingDotsIcons from "../../components/LoadingDotsIcons";

import moment from "moment";
import "moment/locale/da";

import StateContext from "../../config/StateContext";

import userService from "../../services/user.service";
import AccordionBody from "../../components/Accordion/AccordionBody";
import SearchBar from "../../components/SearchBar/SearchBar";

const AllStationaries = () => {
  const navigate = useNavigate();

  const appState = useContext(StateContext);

  const [stationaries, setStationaries] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [searchterm, setSearchTerm] = useState();

  useEffect(() => {
    async function fetchResults() {
      setIsloading(true);
      try {
        const response = searchterm ? await userService.findByTitle("stationary", searchterm) : await userService.getAll("stationary");

        if (response.data) {
          setStationaries(searchterm ? response.data : response.data["stationaries"]);
          setIsloading(false);
        }
      } catch (e) {
        console.log(e);
      }
    }

    fetchResults();
  }, [searchterm, appState.flashMessageges]);

  const findStationary = (uid) => {
    navigate(`/artikel/${uid}`);
  };

  const formatDate = (date) => {
    return moment(date).format("DD MM YYYY") + " kl:" + moment(date).format("HH:mm");
  };

  return (
    <div>
      <SearchBar setSearchTerm={setSearchTerm} searchLabel="Søg efter artikler" searchid="findstationaries" />

      {isloading ? (
        <LoadingDotsIcons />
      ) : (
        <AccordionBody>
          <div>
            {stationaries &&
              stationaries.map((stationary, i) => (
                <Accordion.Item eventKey={i} key={i}>
                  <Accordion.Header>{stationary.name}</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li key={i} style={{ cursor: "pointer" }} onClick={() => findStationary(stationary.slug)}>
                        <ul>
                          <li> Navn: {stationary.name}</li>
                          <li> Skal returneres: {stationary.returnable}</li>
                          <li> Er aktiv: {stationary.is_active}</li>
                          <li> Tilføjet af: {stationary.added_by}</li>
                          <li> Oprettet d. {formatDate(stationary.created_at)}</li>
                          <li> Opdateret d. {formatDate(stationary.updated_at)}</li>
                        </ul>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
          </div>
        </AccordionBody>
      )}
    </div>
  );
};

export default AllStationaries;
